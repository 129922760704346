.MuiPaginationItem-root{
    color: white!important;
}

.Mui-selected{
    color: #3D7670!important;
}

.css-1ygcj2i-MuiTableCell-root,.css-1ex1afd-MuiTableCell-root,.css-1bigob2,.css-q34dxg{
    border-bottom: none!important;
    color: white!important;
}

/* .css-1ygcj2i-MuiTableCell-root,.css-1ex1afd-MuiTableCell-root{

} */

.css-1q1u3t4-MuiTableRow-root:nth-of-type(even),.css-1wbz3t9,.css-1gqug66:nth-of-type(even) {
    background: rgba(255, 255, 255, 0.04);
}


.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root{
    color: white!important;
}

.MuiSelect-select,.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input,.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
    color: white!important;
    font-size: 13px!important;
    line-height: none!important;
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon,.css-1utq5rl{
    fill: white!important;
}


.css-1ex1afd-MuiTableCell-root span{
    color: #FD4060;
}

.colorBlue{
    color: #3D7670!important;
}


.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input, .css-1x5jdmq{
    border-color: white!important;
    outline: white!important;
    padding: 10px!important;
    color: white!important;
    background-color:rgba(255, 255, 255, 0.04) !important;
    border-radius: 4px;
}

.css-1ftyaf0 span, .css-hdw1oc span{
    color: white!important;
}
.MuiDrawer-paper{
    position: absolute !important;
    left: calc(100% - 83.4%) !important;
    height: 100px !important;
    padding: 10px 20px 10px 10px !important;
}
.musicPlayerResponsive{
    position: absolute;
    top: calc(100vh - 75px);
    width: calc(100% - 16.9%);
    background: linear-gradient(269.62deg, rgb(93, 196, 178) -27.14%, rgb(16, 53, 77) 24.63%, rgb(16, 53, 77) 50.49%, rgb(9, 41, 70) 75%) !important;
}
